import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import requestUtil from "../../services/services/requestUtil";
import { CDN_PATH } from '../../constants/variables';
import { useRouteMatch } from "react-router";

const contentLanguages = {
  ru: "ru_RU",
  en: "en_US",
  zh: "zh_CN",
};

const mapStateToProps = (state) => ({
  lang: state.App.lang,
  supportedLanguages: state.App.supportedLanguages,
});

const formatMatchedRoute = (url, departureLocation, arrivalLocation) => {
  let currentUrl = String(url);

  currentUrl = currentUrl.replace(new RegExp(departureLocation, 'g'), String(departureLocation).toUpperCase());
  currentUrl = currentUrl.replace(new RegExp(arrivalLocation, 'g'), String(arrivalLocation).toUpperCase());

  return currentUrl;
}

const MetaTagsHelmet = (props) => {
  const [metaData, setMetaData] = useState({
    path: "",
    title: "",
    description: "",
    image: "",
    canonicalUrl: "",
  });

  const matchedRouteWithoutLang =  useRouteMatch('/timetable/:departureLocation/:arrivalLocation');
  const matchedRouteWithLang =  useRouteMatch('/:lang(en|ru|zh)/timetable/:departureLocation/:arrivalLocation');

  useEffect(function () {
    const { location, supportedLanguages, lang } = props;

    const s7PageInit =
      typeof window.s7PageInit !== "undefined" ? window.s7PageInit : {};

    if (
      s7PageInit.hasOwnProperty("seoData") &&
      s7PageInit.seoData.hasOwnProperty(lang)
    ) {
      setMetaData(s7PageInit.seoData[lang]);
    } else {
      let pathname = location.pathname.replace(
        new RegExp(`^/(${supportedLanguages.join("|")})`),
        ""
      );
      if (pathname.indexOf("timetable") !== -1) pathname = "/timetable";
      getMetaByUrl(pathname, lang);
    }
  }, []);

  const getMetaByUrl = (url, lang) => {
    try {
      let version = -1;
      if (
        typeof s7ContentVersion != "undefined" &&
        s7ContentVersion.hasOwnProperty("seoData")
      ) {
        version = s7ContentVersion["seoData"];
      }
      requestUtil(
        `${process.env.REACT_APP_PREFIX_CONTENT_SERVICE}/api/v1/seo/get?path=${url}&lang=${lang}&version=${version}`
      ).then((data) => {
        setMetaData(data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getCanonicalUrl = () => {
    const { lang, isMainPage } = props;
    const { canonicalUrl } = metaData || {};

    if (isMainPage) {
      return '';
    }

    if (canonicalUrl) return `/${lang}${canonicalUrl}`;

    let currentUrl = location.pathname;
    if (currentUrl.slice(-1) !== "/") currentUrl += "/";

    if (matchedRouteWithLang) {
      currentUrl = formatMatchedRoute(currentUrl, matchedRouteWithLang.params.departureLocation, matchedRouteWithLang.params.arrivalLocation)
    }

    if (matchedRouteWithoutLang) {
      currentUrl = formatMatchedRoute(currentUrl, matchedRouteWithoutLang.params.departureLocation, matchedRouteWithoutLang.params.arrivalLocation)
    }

    return currentUrl;
  };

  const renderHrefLangs = () => {
    const { location, supportedLanguages, lang } = props;
    const { path } = metaData || {};

    return supportedLanguages.map((itemLang) => {
      if (lang !== itemLang) {
        return (
          <link
            rel="alternate"
            key={itemLang}
            hreflang={itemLang}
            href={`https://${window.location.hostname}/${itemLang}${
              path ? path : location.pathname.replace(new RegExp(`^/(${supportedLanguages.join("|")})`),"")
            }`}
          />
        );
      }
    });
  };

  const { lang, location } = props;
  const { path, title, description, image } = metaData || {};

  const pageTitle = title ? title : "S7 Airlines";

  return (
    <Helmet>
      <meta
        http-equiv="Content-Language"
        content={`${contentLanguages[lang]}`}
      />
      <link
        rel="canonical"
        href={`https://${window.location.hostname}${getCanonicalUrl()}`}
      />
      <title>{pageTitle}</title>
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      <meta property="og:type" content="website" />
      <meta name="title" content={pageTitle} />
      <meta property="og:title" content={pageTitle} />
      {path ? (
        <meta
          property="og:url"
          content={`https://${window.location.hostname}/${lang}${path}`}
        />
      ) : (
        <meta
          property="og:url"
          content={`https://${window.location.hostname}${location.pathname}`}
        />
      )}
      {path ? (
        <meta
          name="twitter:site"
          content={`https://${window.location.hostname}/${lang}${path}`}
        />
      ) : (
        <meta
          name="twitter:site"
          content={`https://${window.location.hostname}${location.pathname}`}
        />
      )}
      {image ? (
        <meta property="og:image" content={`${CDN_PATH}${image}`} />
      ) : (
        <meta
          property="og:image"
          content={`https://${window.location.hostname}/favicons/apple-icon-180x180.png`}
        />
      )}
      {image ? (
        <meta property="og:image:secure_url" content={`${CDN_PATH}${image}`} />
      ) : (
        <meta
          property="og:image:secure_url"
          content={`https://${window.location.hostname}/favicons/apple-icon-180x180.png`}
        />
      )}
      {image ? (
        <link rel="image_src" href={`${CDN_PATH}${image}`} />
      ) : (
        <link
          rel="image_src"
          href={`https://${window.location.hostname}/images/icons/icon-600x400.jpg`}
        />
      )}
      {image ? (
        <link rel="twitter:image:src" href={`${CDN_PATH}${image}`} />
      ) : (
        <link
          rel="twitter:image:src"
          href={`https://${window.location.hostname}/favicons/apple-icon-180x180.png`}
        />
      )}
      {image ? (
        <link rel="vk:image" href={`${CDN_PATH}${image}`} />
      ) : (
        <link
          rel="vk:image"
          href={`https://${window.location.hostname}/favicons/apple-icon-180x180.png`}
        />
      )}

      {!image && <meta property="og:image:width" content="180" />}
      {!image && <meta property="og:image:height" content="180" />}
      {!image && <meta property="og:image:type" content="image/png" />}
      {renderHrefLangs()}
    </Helmet>
  );
};

export default connect(mapStateToProps)(React.memo(MetaTagsHelmet));
